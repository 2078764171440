import { Notify } from './../../shared/services/notification.service';
import { environment } from './../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, isDevMode } from '@angular/core';
import { AuthService } from '../auth.service';
declare const window: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: false
})
export class LoginComponent {
  hide = true;
  apiUrl: string = environment.api;
  public loginForm: FormGroup;
  public error: string = '';
  public loading: boolean = false;
  loginSubscription: Subscription = new Subscription();
  redirectUri: string = '';

  constructor(
    private fb: FormBuilder,
    public notify: Notify,
    public authService: AuthService,
    public router: Router,
    activatedRoute: ActivatedRoute
  ) {
    authService.removeAuthToken();
    activatedRoute.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length) {
        this.redirectUri = params.redirect_uri ?? '';
        sessionStorage.setItem("redirect", this.redirectUri)
      }
    });

    this.loginForm = fb.group({
      email: new FormControl(),
      password: new FormControl(),
    });
  }

  onSubmit(value: any): void {
    this.error = '';
    this.loading = true;
    this.loginSubscription = this.authService
      .login(value.email, value.password)
      .subscribe({
        next: (res: any) => {
          this.authService.setSession(res.data);
          let user = res.data.user;
          try {
            window.analytics.identify('Login ', {
              id: user.id,
              email: value.email,
              name: user.name,
            });
          } catch (error) { }

          setTimeout(() => {
            this.loading = false;
            let elm: any = document.getElementById('appLogin');
            elm.submit();
          }, 1000);
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.error = this.authService.showError(err);

          if (this.error) {
            this.notify.error(this.error);
          }
        }
      });
  }

  socialLogin() {
    this.authService.socialLogin();
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }
}
